<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>

      <div class="container-new pd2">
        <div class="d-flex align-items-center">
          <router-link
            v-if="UserData.usertype == 'member'"
            :to="{ name: 'tanghuay-home' }"
          >
            <button class="bnt-home">
              <i class="fas fa-home" />
            </button>
          </router-link>
          <div
            class="txt-right d-flex justify-content-between bg-dark-red-3 mt-2"
          >
            <span class="txt-right__text">หวย{{ NameShow }}</span>
            <span
              class="txt-right__text"
            ><i class="far fa-clock text-danger" />
              {{ CloseTimeShow ? CloseTimeShow : "-" }} ชม.</span>
          </div>
        </div>

        <div v-if="!payOut">
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-top: 10px"
          >
            <router-link :to="{ name: 'tanghuay-round' }">
              <span class="text-white">
                <i class="far fa-arrow-circle-left" /> ย้อนกลับ</span>
            </router-link>
            <div class="">
              <b-button
                size="sm"
                style="height: 25px !important"
                @click="showRule(Title.LottoName)"
              >
                กติกา
              </b-button>
            </div>
          </div>

          <b-row class="match-height">
            <b-collapse
              v-if="items.length > 0"
              id="Show-number"
              class="collapse show"
            >
              <b-col
                sm="6"
                md="4"
                lg="4"
                class="col-3 h-100"
              >
                <div
                  class="bg-dark-red-1 bg-light w-100 fixed-scroll"
                  style="margin-top: 7px"
                  :class="{
                    'h-fix-1':
                      (!selectNumbet3U || !selectNumbet3T || !selectNumbet3D || !selectNumbet2U || !selectNumbet2T || !selectNumbet2D || !selectNumbet1U || !selectNumbet1D) && !Mode,
                    'h-fix-2': (selectNumbet3U || selectNumbet3T) && !Mode,
                    'h-fix-3': (selectNumbet2U || selectNumbet2D) && !Mode,
                    'h-fix-4': (selectNumbet1U || selectNumbet1D) && !Mode,
                    'h-fix-5':
                      (!selectNumbet3U || !selectNumbet3T || !selectNumbet2U || !selectNumbet2D || !selectNumbet1U || !selectNumbet1D) && Mode,
                    'h-fix-6': (selectNumbet3U || selectNumbet3T) && Mode,
                    'h-fix-7':
                      (selectNumbet2U || selectNumbet2D ) && Mode,
                    'h-fix-8': (selectNumbet1U || selectNumbet1D) && Mode,
                  }"
                >
                  <div
                    v-for="(item, index) in items"
                    :key="index"
                    style="padding: 2px"
                  >
                    {{
                      index === 0 || item.num_type !== items[index - 1].num_type
                        ? item.num_type
                        : ""
                    }}
                    <div class="deleteItems">
                      <b-button
                        variant="relief-secondary"
                        block
                      >
                        {{ item.num_lot }}
                        <span
                          class="rounded-circle pointer"
                          @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(item.bet_digit, item.bet_type, item.num_lot)"
                        >x</span>
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-button
                  block
                  variant="relief-danger"
                  style="height: 50px"
                  class="mt-1 btn-dangere"
                  @click="Undo()"
                >
                  ล้างโพย
                </b-button>
              </b-col>
            </b-collapse>

            <b-col class="mb-1">
              <div class="card-tang bg-dark-red-1 fixed-scroll text-dark">
                <div class="d-flex justify-content-between align-items-center">
                  <div
                    class="select-one cursor-pointer"
                    :class="{ actives: !Mode }"
                    @click="
                      (Mode = false),
                      (selectNumbet2U = false),
                      (selectNumbet2D = false),
                      (selectNumbet2T = false)
                    "
                  >
                    เลือกจากแผง
                  </div>
                  <div
                    class="select-two cursor-pointer"
                    :class="{ actives: Mode }"
                    @click="Mode = true"
                  >
                    กดเลข
                  </div>
                </div>

                <!-- เลือกหวยจากแผง -->

                <div
                  class="app__lotto-slug slug-classic pp"
                  style="margin-top: 3px"
                >
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet3U }"
                    @click="TogType('up', 3)"
                  >
                    <span class="label">3 ตัวบน</span>
                    <span class="rate">{{ RateAll.Three.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet3T }"
                    @click="TogType('tot', 3)"
                  >
                    <span class="label">3 ตัวโต๊ด</span>
                    <span class="rate">{{ RateAll.Three.Tot.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet3D }"
                    @click="TogType('flip', 3)"
                  >
                    <span class="label">3 ตัวกลับ</span>
                    <span class="rate">ตัวเลือก</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet2U }"
                    @click="TogType('up', 2)"
                  >
                    <span class="label">2 ตัวบน</span>
                    <span class="rate">{{ RateAll.Two.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet2D }"
                    @click="TogType('down', 2)"
                  >
                    <span class="label">2 ตัวล่าง</span>
                    <span class="rate">{{ RateAll.Two.Down.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer"
                    :class="{ active: selectNumbet2T }"
                    @click="TogType('flip', 2)"
                  >
                    <span class="label">2 ตัวกลับ</span>
                    <span class="rate">ตัวเลือก</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer slug-item-lg"
                    :class="{ active: selectNumbet1U }"
                    @click="TogType('up', 1)"
                  >
                    <span class="label">วิ่งบน</span>
                    <span class="rate">{{ RateAll.One.Up.Rate }}</span>
                  </div>
                  <div
                    class="slug-item cursor-pointer slug-item-lg"
                    :class="{ active: selectNumbet1D }"
                    @click="TogType('down', 1)"
                  >
                    <span class="label">วิ่งล่าง</span>
                    <span class="rate">{{ RateAll.One.Down.Rate }}</span>
                  </div>
                </div>
                <div>
                  <div v-if="!Mode">
                    <hr
                      style="border-top: 1px solid #1e1e1e"
                      class="mt-1 mb-1"
                    >
                    <!-- หวย 3 ตัว -->
                    <ul
                      v-if="selectNumbet3U || selectNumbet3T"
                      class="bet-group pp"
                    >
                      <li
                        class="number-item"
                        :class="{ active: SubThree0 }"
                        @click="TogThreeType(0, 3)"
                      >
                        <span class="label">000</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree1 }"
                        @click="TogThreeType(100, 3)"
                      >
                        <span class="label">100</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree2 }"
                        @click="TogThreeType(200, 3)"
                      >
                        <span class="label">200</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree3 }"
                        @click="TogThreeType(300, 3)"
                      >
                        <span class="label">300</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree4 }"
                        @click="TogThreeType(400, 3)"
                      >
                        <span class="label">400</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree5 }"
                        @click="TogThreeType(500, 3)"
                      >
                        <span class="label">500</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree6 }"
                        @click="TogThreeType(600, 3)"
                      >
                        <span class="label">600</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree7 }"
                        @click="TogThreeType(700, 3)"
                      >
                        <span class="label">700</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree8 }"
                        @click="TogThreeType(800, 3)"
                      >
                        <span class="label">800</span>
                      </li>
                      <li
                        class="number-item"
                        :class="{ active: SubThree9 }"
                        @click="TogThreeType(900, 3)"
                      >
                        <span class="label">900</span>
                      </li>
                    </ul>

                    <!-- หัว 2 ตัว -->
                    <div
                      v-if="selectNumbet2U || selectNumbet2D"
                      class="row pp m-0"
                    >
                      <div class="col-12 col-lg-4 text-dark">
                        <strong>19 ประตู</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D0 = CheckTog(
                                'nine',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D0.SwipChang,
                                PickTwoArr,
                                NineDoor.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D1 = CheckTog(
                                'nine',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D1.SwipChang,
                                PickTwoArr,
                                NineDoor.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D2 = CheckTog(
                                'nine',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D2.SwipChang,
                                PickTwoArr,
                                NineDoor.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D3 = CheckTog(
                                'nine',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D3.SwipChang,
                                PickTwoArr,
                                NineDoor.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D4 = CheckTog(
                                'nine',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D4.SwipChang,
                                PickTwoArr,
                                NineDoor.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D5 = CheckTog(
                                'nine',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D5.SwipChang,
                                PickTwoArr,
                                NineDoor.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D6 = CheckTog(
                                'nine',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D6.SwipChang,
                                PickTwoArr,
                                NineDoor.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D7 = CheckTog(
                                'nine',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D7.SwipChang,
                                PickTwoArr,
                                NineDoor.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D8 = CheckTog(
                                'nine',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D8.SwipChang,
                                PickTwoArr,
                                NineDoor.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (NineDoor.D9 = CheckTog(
                                'nine',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                NineDoor.D9.SwipChang,
                                PickTwoArr,
                                NineDoor.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-lg-4 text-dark">
                        <strong>รูดหน้า</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D0 = CheckTog(
                                'front',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D0.SwipChang,
                                PickTwoArr,
                                RootFront.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D1 = CheckTog(
                                'front',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D1.SwipChang,
                                PickTwoArr,
                                RootFront.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D2 = CheckTog(
                                'front',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D2.SwipChang,
                                PickTwoArr,
                                RootFront.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D3 = CheckTog(
                                'front',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D3.SwipChang,
                                PickTwoArr,
                                RootFront.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D4 = CheckTog(
                                'front',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D4.SwipChang,
                                PickTwoArr,
                                RootFront.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D5 = CheckTog(
                                'front',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D5.SwipChang,
                                PickTwoArr,
                                RootFront.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D6 = CheckTog(
                                'front',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D6.SwipChang,
                                PickTwoArr,
                                RootFront.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D7 = CheckTog(
                                'front',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D7.SwipChang,
                                PickTwoArr,
                                RootFront.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D8 = CheckTog(
                                'front',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D8.SwipChang,
                                PickTwoArr,
                                RootFront.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootFront.D9 = CheckTog(
                                'front',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootFront.D9.SwipChang,
                                PickTwoArr,
                                RootFront.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                      <div class="col-12 col-xl-4 text-dark">
                        <strong>รูดหลัง</strong>
                        <ul class="bet-quick">
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D0 = CheckTog(
                                'back',
                                PickTwoArr,
                                0
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D0.SwipChang,
                                PickTwoArr,
                                RootBack.D0.status
                              )
                            "
                          >
                            0
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D1 = CheckTog(
                                'back',
                                PickTwoArr,
                                1
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D1.SwipChang,
                                PickTwoArr,
                                RootBack.D1.status
                              )
                            "
                          >
                            1
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D2 = CheckTog(
                                'back',
                                PickTwoArr,
                                2
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D2.SwipChang,
                                PickTwoArr,
                                RootBack.D2.status
                              )
                            "
                          >
                            2
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D3 = CheckTog(
                                'back',
                                PickTwoArr,
                                3
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D3.SwipChang,
                                PickTwoArr,
                                RootBack.D3.status
                              )
                            "
                          >
                            3
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D4 = CheckTog(
                                'back',
                                PickTwoArr,
                                4
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D4.SwipChang,
                                PickTwoArr,
                                RootBack.D4.status
                              )
                            "
                          >
                            4
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D5 = CheckTog(
                                'back',
                                PickTwoArr,
                                5
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D5.SwipChang,
                                PickTwoArr,
                                RootBack.D5.status
                              )
                            "
                          >
                            5
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D6 = CheckTog(
                                'back',
                                PickTwoArr,
                                6
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D6.SwipChang,
                                PickTwoArr,
                                RootBack.D6.status
                              )
                            "
                          >
                            6
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D7 = CheckTog(
                                'back',
                                PickTwoArr,
                                7
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D7.SwipChang,
                                PickTwoArr,
                                RootBack.D7.status
                              )
                            "
                          >
                            7
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D8 = CheckTog(
                                'back',
                                PickTwoArr,
                                8
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D8.SwipChang,
                                PickTwoArr,
                                RootBack.D8.status
                              )
                            "
                          >
                            8
                          </li>
                          <li
                            class="quick-item"
                            :class="{
                              active: (RootBack.D9 = CheckTog(
                                'back',
                                PickTwoArr,
                                9
                              )).status,
                            }"
                            @click="
                              CheckPickNum(
                                RootBack.D9.SwipChang,
                                PickTwoArr,
                                RootBack.D9.status
                              )
                            "
                          >
                            9
                          </li>
                        </ul>
                      </div>
                    </div>

                    <!-- เลขวิ่ง -->
                    <ul
                      v-if="selectNumbet1D || selectNumbet1U"
                      class="bet-number pp"
                    >
                      <li
                        v-for="item in PickOneArr"
                        :key="item.Num"
                        :class="{ activede: ActiveBet(item) }"
                        class="number-item number-lg"
                        @click="PickNum(item, PickOneArr)"
                      >
                        <span class="label">{{ item.Num }}</span>
                      </li>
                    </ul>

                    <!-- เลือกจากแผง -->
                    <ul
                      v-if="selectNumbet3U || selectNumbet3T"
                      class="bet-number pp"
                    >
                      <li
                        v-for="item in 100"
                        :key="item.num"
                        :class="{
                          active: ActiveBet(PickThreeArr[item - 1 + PickThree]),
                        }"
                        class="number-item2"
                        @click="
                          PickNum(
                            PickThreeArr[item - 1 + PickThree],
                            PickThreeArr
                          )
                        "
                      >
                        {{ PickThreeArr[item - 1 + PickThree].Num }}
                      </li>
                    </ul>
                    <ul
                      v-if="selectNumbet2U || selectNumbet2D"
                      class="bet-number pp"
                    >
                      <li
                        v-for="item in PickTwoArr"
                        :key="item.Num"
                        :class="{ active: ActiveBet(item) }"
                        class="number-item2"
                        @click="PickNum(item, PickTwoArr)"
                      >
                        {{ item.Num }}
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <div class="padding-bottom-10" /> -->
                <!-- สิ้นสุดเลือกหวยจากแผง -->

                <!-- เลือกหวยกดเลข -->
                <div v-if="Mode && (selectNumbet3U || selectNumbet3T || selectNumbet2U || selectNumbet2D || selectNumbet1D || selectNumbet1U )">
                  <ul
                    v-if="selectNumbet2U || selectNumbet2D"
                    class="bet-group pp"
                  >
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.NineDoor }"
                      @click="
                        (TwoPushSubType.NineDoor = !TwoPushSubType.NineDoor),
                        (TwoPushSubType.RootFront = false),
                        (TwoPushSubType.RootBack = false)
                      "
                    >
                      <span class="label">19 ประตู</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.RootFront }"
                      @click="
                        (TwoPushSubType.RootFront = !TwoPushSubType.RootFront),
                        (TwoPushSubType.NineDoor = false),
                        (TwoPushSubType.RootBack = false)
                      "
                    >
                      <span class="label">รูดหน้า</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.RootBack }"
                      @click="
                        (TwoPushSubType.RootBack = !TwoPushSubType.RootBack),
                        (TwoPushSubType.NineDoor = false),
                        (TwoPushSubType.RootFront = false)
                      "
                    >
                      <span class="label">รูดหลัง</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.Ble }"
                      @click="SelctTwoTool('เลขเบิ้ล')"
                    >
                      <span class="label">เลขเบิ้ล</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoHight }"
                      @click="SelctTwoTool('สองตัวสูง')"
                    >
                      <span class="label">สองตัวสูง</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoLow }"
                      @click="SelctTwoTool('สองตัวต่ำ')"
                    >
                      <span class="label">สองตัวต่ำ</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoEven }"
                      @click="SelctTwoTool('สองตัวคู่')"
                    >
                      <span class="label">สองตัวคู่</span>
                    </li>
                    <li
                      class="number-item"
                      :class="{ active: TwoPushSubType.TwoOdd }"
                      @click="SelctTwoTool('สองตัวคี่')"
                    >
                      <span class="label">สองตัวคี่</span>
                    </li>
                  </ul>
                  <hr
                    style="border-top: 1px solid #1e1e1e"
                    class="mt-1 mb-1"
                  >
                  <div style="width: 98% !important">
                    <h6
                      v-if="!CheckBetType"
                      class="text-center"
                    >
                      ระบุตัวเลข {{ CalMaxLength() }} ตัว
                    </h6>
                    <h6
                      v-else
                      class="text-center"
                    >
                      กรุณาเลือกประเภท
                    </h6>
                    <!-- {{ CheckBetType }} -->
                    <div class="text-center mt-1">
                      <otp-input
                        v-if="BugOtp === true"
                        v-model="NumBet"
                        class="style-1"
                        :length="CalMaxLength()"
                        pattern="[^0-9]+"
                        :ignore-pattern="false"
                        :size="32"
                        :disabled="CheckBetType"
                        @valid="handleOnComplete()"
                      />
                    </div>
                    <div class="row pl-1 pr-1 w-100">
                      <div class="col-4 mmb-1">
                        <button
                          data-id="1"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(1)"
                        >
                          1
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="2"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(2)"
                        >
                          2
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="3"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(3)"
                        >
                          3
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="4"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(4)"
                        >
                          4
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="5"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(5)"
                        >
                          5
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="6"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(6)"
                        >
                          6
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="7"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(7)"
                        >
                          7
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="8"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(8)"
                        >
                          8
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="9"
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(9)"
                        >
                          9
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <!-- @click="NumBet = ''" -->
                        <button
                          v-b-modal.modal-1
                          class="button-num-cals"
                          @click="Undo()"
                        >
                          ย้อนกลับ
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          class="button-num-cal push-btn-effect"
                          :disabled="CheckBetType"
                          @click="Betting(0)"
                        >
                          0
                        </button>
                      </div>
                      <div class="col-4 mmb-1">
                        <button
                          data-id="delete"
                          class="button-num-calss"
                          @click="NumBet = NumBet.slice(0, -1)"
                        >
                          <i
                            aria-hidden="true"
                            class="fas fa-backspace"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    !(
                      selectNumbet3U ||
                      selectNumbet3T ||
                      selectNumbet2U ||
                      selectNumbet2D ||
                      selectNumbet1D ||
                      selectNumbet1U
                    )
                  "
                  class="card__loading"
                >
                  <i
                    class="icon fas fa-mouse-pointer"
                    size="10"
                  />
                  <span class="label mb-2">โปรดเลือกประเภท</span>
                </div>
              </div>
              <div class="d-flex justify-content-center mt-1">
                <b-button
                  v-if="!payOut"
                  v-b-toggle.Show-number
                  class="btn-green"
                  block
                  variant="relief-warning"
                  :disabled="items.length <= 0"
                  style="height: 50px"
                  @click="toggleShowNumber"
                >
                  <span
                    style="font-size: 15px"
                  >ทั้งหมด {{ items.length }} รายการ</span>
                  <p
                    v-if="!items.length"
                    style="margin-top: 2px; font-size: 12px; font-weight: 400"
                  >
                    ยังไม่มีรายการแทง
                  </p>
                  <p
                    v-if="items.length"
                    style="margin-top: 2px; font-weight: 400; font-size: 11px"
                  >
                    <font v-if="!isShowNumber">
                      เปิดดูเลขที่เลือก
                    </font>
                    <font v-if="isShowNumber">
                      ปิดดูเลขที่เลือก
                    </font>
                  </p>
                </b-button>

                <b-button
                  v-if="!payOut"
                  variant="relief-success"
                  class="w-50 btn-gold"
                  :disabled="items.length <= 0"
                  style="height: 50px"
                  @click="payOut = !payOut"
                >
                  ใส่ราคา
                </b-button>
              </div>
            </b-col>
          </b-row>

          <!-- <b-col
            col="12"
            sm="12"
            lg="12"
            md="12"
          >

          </b-col> -->
          <div
            v-if="
              selectNumbet3U ||
                selectNumbet3T ||
                selectNumbet2U ||
                selectNumbet2D
            "
            class="paddinsg-bottom-10"
          />
        </div>
        <!-- footer check list -->
        <!-- <div
          v-if="!payOu"
          class="check-txt"
        >
          <div
            class="app__lotto-stake-bar"
          >
            <div
              v-if="items.length"
              class="stake-detail cursor-pointer"
              @click="payOut = !payOut"
            >
              <div
                class="label"
              >
                รายการแทงทั้งหมด {{ items.length }} รายการ
              </div>
            </div>
            <div
              v-if="!items.length"
              class="stake-detail no-bet"
            >
              <div
                class="label"
              >
                ยังไม่มีรายการแทง
              </div>
            </div>
            <div class="statk-detail">
              <div />
            </div>
            <div
              v-if="items.length"
              class="stake-action amount"
              @click="payOut = !payOut"
            >
              <div class="label d-flex align-items-center">
                <i
                  class="fal fa-usd-circle"
                  style="font-size: 20px; margin-right: 5px;"
                />
                ใส่ราคา
              </div>
            </div>

            <div
              v-if="!items.length"
              class="stake-action amount"
            >
              <div class="label d-flex align-items-center">
                <i
                  class="fal fa-usd-circle"
                  style="font-size: 20px; margin-right: 5px;"
                />
                เลือกเลข
              </div>
            </div>
          </div>
        </div> -->

        <b-col
          v-if="!payOut"
          lg="12"
          md="12"
          order="2"
          order-lg="6"
        >
          <div class="p-1 card-info justify-content-center">
            <div>
              <h5 class="font-medium-1 font-weight-bolder m-0 text-white">
                เงื่อนไขการแทง
              </h5>
            </div>
            <!--condition 3 ตัว -->
            <div class="d-flex justify-content-center flex-wrap">
              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.Three.Up.Rate }}</p>
                </aside>
                <h4>3 ตัวบน</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Three.Up.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Three.Up.Max) }}
                </p>
              </div>

              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.Three.Up.Rate }}</p>
                </aside>
                <h4>3 ตัวโต๊ด</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Three.Up.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Three.Up.Max) }}
                </p>
              </div>

              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.Two.Up.Rate }}</p>
                </aside>
                <h4>2 ตัวบน</h4>
                <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Two.Up.Min) }}</p>
                <p>แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Two.Up.Max) }}</p>
              </div>

              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.Two.Down.Rate }}</p>
                </aside>
                <h4>2 ตัวล่าง</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Two.Down.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.Two.Down.Max) }}
                </p>
              </div>

              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.One.Up.Rate }}</p>
                </aside>
                <h4>วิ่งบน</h4>
                <p>แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.One.Up.Min) }}</p>
                <p>แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.One.Up.Max) }}</p>
              </div>

              <div class="promoBox warning-box danger-ribbon">
                <aside>
                  <p>&nbsp;จ่าย {{ RateAll.One.Down.Rate }}</p>
                </aside>
                <h4>วิ่งล่าง</h4>
                <p>
                  แทงขั้นต่ำต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.One.Down.Min) }}
                </p>
                <p>
                  แทงสูงสุดต่อครั้ง/เลข : {{ OtherMethod.Commas(RateAll.One.Down.Max) }}
                </p>
              </div>

              <!-- <ul class="rate-list m-0">
                <li
                  v-if="selectNumbet3U"
                  class="rate-item mb-1"
                >
                  <div class="rate-header">
                    <span class="label text-lg">3 ตัวบน</span> <span class="amount">{{ RateAll.Three.Up.Rate }}</span>
                  </div> <div class="row row-sm text-center">
                    <div class="col-6">
                      <span class="txt-small">แทงขั้นต่ำต่อครั้ง/เลข</span> <div class="text-lg">
                        {{ OtherMethod.Commas(RateAll.Three.Up.Min) }}
                      </div>
                    </div> <div class="col-6">
                      <span class="txt-small">สูงสุดต่อเลข</span> <div class="text-lg">
                        {{ OtherMethod.Commas(RateAll.Three.Up.Max) }}
                      </div>
                    </div>
                  </div>
                </li>

                <li
                  v-if="selectNumbet3T"
                  class="rate-item mb-1"
                >
                  <div class="rate-header">
                    <span class="label text-lg">3 ตัวโต๊ด</span> <span class="amount">{{ RateAll.Three.Tot.Rate }}</span>
                  </div> <div class="row row-sm text-center">
                    <div class="col-6">
                      <span class="txt-small">แทงขั้นต่ำต่อครั้ง/เลข</span> <div class="text-lg">
                        {{ OtherMethod.Commas(RateAll.Three.Up.Min) }}
                      </div>
                    </div> <div class="col-6">
                      <span class="txt-small">สูงสุดต่อเลข</span> <div class="text-lg">
                        {{ OtherMethod.Commas(RateAll.Three.Up.Max) }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul> -->
            </div>

            <!--condition 2ตัว -->
          </div>
        </b-col>

        <b-col
          v-if="!payOut"
          lg="12"
          md="12"
          order="2"
          order-lg="6"
        >
          <div class="p-1 card-info mt-1">
            <b-row>
              <b-col
                md="6"
                cols="12"
              >
                <div class="txt_head">
                  <h5
                    id="num_check"
                    class="text-yellow-2"
                  >
                    หมายเลขจ่ายตาม %
                  </h5>
                </div>
                <div class="mb-1 text-white">
                  <div class="condition">
                    <div v-if="Title.LimitU3.length > 0">
                      3 ตัวบน <br>
                      <b-badge
                        v-for="item in Title.LimitU3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD3.length > 0">
                      3 ตัวล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitT3.length > 0">
                      3 โต๊ด <br>
                      <b-badge
                        v-for="item in Title.LimitT3"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitU2.length > 0">
                      2 ตัวบน <br>
                      <b-badge
                        v-for="item in Title.LimitU2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD2.length > 0">
                      2 ตัวล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitT2.length > 0">
                      2 ตัวโต๊ด(บน) <br>
                      <b-badge
                        v-for="item in Title.LimitT2"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitU1.length > 0">
                      วิ่งบน <br>
                      <b-badge
                        v-for="item in Title.LimitU1"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="Title.LimitD1.length > 0">
                      วิ่งล่าง <br>
                      <b-badge
                        v-for="item in Title.LimitD1"
                        :key="item._id"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item.NumLot }}
                      </b-badge>
                    </div>
                  </div>
                </div>

                <!-- แสดงผล mobile -->
                <hr class="d-block d-md-none">
              </b-col>

              <b-col
                md="6"
                cols="12"
                class="text-white"
              >
                <div class="txt_head">
                  <h5 class="text-yellow-2">
                    หมายเลขปิดรับ
                  </h5>
                </div>
                <div class="mb-1">
                  <div class="condition">
                    <div v-if="CloseBet.CloseU3.length > 0">
                      3 ตัวบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU3"
                        :key="`U3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD3.length > 0">
                      3 ตัวล่าง <br>
                      <b-badge
                        v-for="item in CloseBet.CloseD3"
                        :key="`D3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseT3.length > 0">
                      3 โต๊ด <br>
                      <b-badge
                        v-for="item in CloseBet.CloseT3"
                        :key="`T3${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseU2.length > 0">
                      2 ตัวบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU2"
                        :key="`U2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD2.length > 0">
                      2 ตัวล่าง <br>
                      <b-badge
                        v-for="item in CloseBet.CloseD2"
                        :key="`D2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseT2.length > 0">
                      2 ตัวโต๊ด(บน) <br>
                      <b-badge
                        v-for="item in CloseBet.CloseT2"
                        :key="`T2${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseU1.length > 0">
                      วิ่งบน <br>
                      <b-badge
                        v-for="item in CloseBet.CloseU1"
                        :key="`U1${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                      <hr>
                    </div>
                    <div v-if="CloseBet.CloseD1.length > 0">
                      วิ่งล่าง<br>
                      <b-badge
                        v-for="item in CloseBet.CloseD1"
                        :key="`D1${item}`"
                        variant="primary"
                        class="mr-1"
                      >
                        {{ item }}
                      </b-badge>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>

        <div v-if="payOut">
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-top: 10px"
          >
            <a @click="(payOut = !payOut), (confirm = false)">
              <span class="text-white">
                <i class="far fa-arrow-circle-left" /> ย้อนกลับ</span>
            </a>
          </div>

          <div class="card-tang bg-dark-red-1 text-dark">
            <div class="nav-text pd-h-20 text-center pt-1 pb-1 font-medium-3">
              รายการแทงทั้งหมด {{ items.length }} รายการ
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == '3ตัวบน').length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  3 ตัวบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU3">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == '3ตัวบน'
                    )"
                    :key="item.id"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>

                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    <!-- {{ item.win }} -->
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == '3ตัวโต๊ด').length > 0
              "
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  3 ตัวโต๊ด
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesT3">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == '3ตัวโต๊ด'
                    )"
                    :key="item.id"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == '2ตัวบน').length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  2 ตัวบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU2">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == '2ตัวบน'
                    )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == '2ตัวล่าง').length > 0
              "
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  2 ตัวล่าง
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesD2">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == '2ตัวล่าง'
                    )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter((ele) => ele.num_type == 'วิ่งบน').length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  วิ่งบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU1">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == 'วิ่งบน'
                    )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="
                items.filter((ele) => ele.num_type == 'วิ่งล่าง').length > 0
              "
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  วิ่งล่าง
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div>
                  <div class="stake-rate">
                    อัตราจ่าย
                  </div>
                  <div class="stake-win-rate">
                    ชนะ
                  </div>
                  <div class="stake-amount">
                    จำนวนเงิน
                  </div>
                  <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesD1">
                  <div
                    v-for="(item, index) in items.filter(
                      (ele) => ele.num_type == 'วิ่งล่าง'
                    )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}
                    </div>
                    <div class="stake-number">
                      {{ item.num_lot }}
                    </div>
                    <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div>
                    <div class="stake-win-rate">
                      <span class="rate-wrapper">{{
                        OtherMethod.Commas(item.win_rate * item.price)
                      }}</span>
                    </div>
                    <div class="stake-amount">
                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max
                            ) === true
                            ? `ระบุจำนวนเงิน ${OtherMethod.Commas(
                              CheckAmount(item.num_type).Min
                            )}-${OtherMethod.Commas(CheckAmount(item.num_type).Max)} บาท`
                            : ""
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                      <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div>
                    <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="
                          RemovePush(items, item.num_lot, item.num_type),
                          DisableInList(item.bet_digit, item.bet_type, item.num_lot)
                        "
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>
          </div>

          <div
            v-if="confirm == false"
            class="card-tang mb-1 bg-dark-red-3"
          >
            <div class="card-bodys">
              <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                  <span
                    id=""
                    class="input-group-text"
                  >ราคาเท่ากัน</span>
                </div>
                <input
                  v-model="pricebase"
                  type="tel"
                  value="5"
                  maxlength="7"
                  class="form-control"
                  style="padding: 0.25rem 0.5rem"
                  @input="AddPriceBase(pricebase)"
                >
              </div>
              <div class="stake-amount-group">
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(5, (pricebase = 5))"
                >
                  5
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(10, (pricebase = 10))"
                >
                  10
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(20, (pricebase = 20))"
                >
                  20
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(50, (pricebase = 50))"
                >
                  50
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(100, (pricebase = 100))"
                >
                  100
                </button>
              </div>
              <div class="row">
                <div class="col p-0">
                  <div class="card-tang card-status">
                    <div class="card-headers bg-darker text-white text-center">
                      <small>เครดิตคงเหลือ</small>
                    </div>
                    <div class="card-bodys p-1 text-center">
                      {{ OtherMethod.Commas(MainWallet) }} ฿
                    </div>
                  </div>
                </div>
                <div class="col p-0">
                  <div class="card-tang card-status">
                    <div class="card-headers bg-darker text-white text-center">
                      <small>ยอดเดิมพัน</small>
                    </div>
                    <div class="card-bodys p-1 text-center">
                      {{ OtherMethod.Commas(CalSumAmount()) }} ฿
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="confirm == false"
            class="row row-sm"
          >
            <div
              class="col-4 p-0"
              style="padding-right: 5px !important"
            >
              <button
                class="btn btn-dangere btn-block btn-sm"
                block
                @click="Undo(), (payOut = !payOut), (confirm = false)"
              >
                ล้างโพย
              </button>
            </div>
            <div class="col-8 p-0">
              <button
                class="btn btn-gold btn-block btn-sm"
                block
                @click="PreSubmit()"
              >
                ส่งโพย
              </button>
            </div>
          </div>

          <div
            v-if="confirm == true"
            class="row row-sm mb-4 mt-1"
          >
            <div class="col-4 p-0">
              <button
                type="button"
                class="btn w-100 btn-danger btn-sm"
                @click="confirm = false"
              >
                ยกเลิก
              </button>
            </div>
            <div class="col-8">
              <button
                type="button"
                class="btn w-100 btn-gold btn-sm"
                @click="SummitBet(), (payOut = !payOut)"
              >
                ยืนยัน
              </button>
            </div>
          </div>

          <div class="padding-bottom-10" />
        </div>
      </div>

      <!-- modal alert -->
      <b-modal
        ref="modal-alert"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="text-center">
          <b-avatar
            size="100"
            icon="AlertCircleIcon"
            class="mb-1"
            variant="warning"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="100"
            />
          </b-avatar>
          <h3>แจ้งเตือนปิดรับแทง !!</h3>
          <p>เนื่องจากเลขดังกล่าวได้ถึงยอดที่รับแทงแล้ว</p>

          <b-table
            small
            responsive="sm"
            :items="CloseBetModal"
            :fields="fieldsModal"
          />

          <b-button
            variant="warning"
            @click="showAlertHide()"
          >
            ตกลง
          </b-button>
        </div>
      </b-modal>
      <b-modal
        ref="my-modal"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="text-center mt-2 mb-2">
          <b-avatar
            variant="light-success"
            size="80"
          >
            <i class="fas fa-thumbs-up fa-3x" />
          </b-avatar>

          <h3 class="font-weight-bolder text-success mt-1">
            ระบบได้ทำการส่งโพยของท่านให้เรียบร้อยแล้ว
          </h3>
        </div>
        <table
          style="width: 100%"
          class="pl-1 pr-1 mb-1"
        >
          <tbody>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                <h4 class="font-weight-bolder">
                  {{ Slip.LottoHeadName }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>วันที่</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.date }}
                </h4>
              </td>
              <td>
                <small>เวลา</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.time }} น.
                </h4>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <small>โพยเลขที่</small>
                <h4 class="font-weight-bolder">
                  #{{ Slip.listID }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>รายการแทง</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.count }} รายการ
                </h4>
              </td>
              <td>
                <small>ยอดเดิมพันทั้งหมด</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.SumAmount }} บาท
                </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-6">
            <router-link :to="{ path: `/list-huay/${Slip.listID}` }">
              <b-button
                variant="danger"
                block
              >
                ดูโพย
              </b-button>
            </router-link>
          </div>

          <div class="col-6">
            <b-button
              variant="warning"
              block
              style="color: #000 !important"
              @click="hideModal"
            >
              แทงหวยต่อ
            </b-button>
          </div>
        </div>
      </b-modal>
      <!-- <this-footer /> -->
    </b-overlay>
    <div class="padding-bottom-10" />
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  BModal,
  BTable,
  BFormInput,
  BAvatar,
  VBToggle,
  BButton,
  BOverlay,
  BIconController,
  BRow,
  BCol,
  BCollapse,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OtpInput from '@8bu/vue-otp-input'
import OtherMethod from './th_other_method'

export default {
  components: {
    BOverlay,
    BIconController,
    OtpInput,
    BModal,
    BTable,
    BButton,
    BFormInput,
    BAvatar,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCollapse,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Mode: false,
      isActive1: false,
      isShowNumber: false,
      BetType3: '',
      add_remark: '',
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      optionsMember: '',
      member: '',
      rule: null,
      confirm: false,
      countArry: 0,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      LottoHeadList: [],
      LottoSubList: [],
      SumAmount: 0,
      AmountBet: null,
      AmountBet2: null,
      NumBet: '',
      NumArrCart: '',
      NumArrCartInput: null,
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: ['3ตัวบน', '3ตัวโต๊ด'],
      Interval: null,
      IntervalRate: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      fieldBill: [
        // { key: 'index', label: 'ลำดับที่' },
        { key: 'remark', label: 'ชื่อลูกค้า', thStyle: { width: '25%' } },
        { key: 'lotto_type', label: 'ประเภทหวย', thStyle: { width: '20%' } },
        { key: 'amount', label: 'เงินเดิมพัน', thStyle: { width: '20%' } },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => momenttz(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          thStyle: { width: '20%' },
        },
        // { key: 'status', label: 'สถานะ', thStyle: { width: '15%' } },
        { key: 'actions', label: 'สถานะ', thStyle: { width: '15%' } },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoTypeHead: null,
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      statusCheck: false,
      Num_Arr: null,
      MainWallet: 0,
      NameShow: '',
      step1: false,
      NumArrCartInputStep1: '',
      NumArrCartInputStep1Show: [],
      NumArrCartInputStep2Show: [],
      NumArrCartInputStep2Cal: [],
      remarkBill: '',
      CloseTimeShow: '',
      currentPage: 1,
      perPage: 5,
      HistoryList: [],
      totalRows: 0,
      pageOptions: [5, 10, 15, 20],
      selectNumbet3U: false,
      selectNumbet3T: false,
      selectNumbet3D: false,
      selectNumbet2U: false,
      selectNumbet2T: false,
      selectNumbet2D: false,
      selectNumbet1U: false,
      selectNumbet1D: false,
      SubThree0: true,
      SubThree1: false,
      SubThree2: false,
      SubThree3: false,
      SubThree4: false,
      SubThree5: false,
      SubThree6: false,
      SubThree7: false,
      SubThree8: false,
      SubThree9: false,
      PickThreeArr: OtherMethod.GenNum(1000, 3),
      PickTwoArr: OtherMethod.GenNum(100, 2),
      PickOneArr: OtherMethod.GenNum(10, 1),
      PickThree: 0,
      NineDoor: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootFront: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootBack: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      TwoPushSubType: {
        NineDoor: false,
        RootFront: false,
        RootBack: false,
        Ble: false,
        TwoHight: false,
        TwoLow: false,
        TwoEven: false,
        TwoOdd: false,
      },
      pricebase: 0,
    }
  },
  computed: {
    PrintAuto() {
      return this.$store.state.app.printListAuto
    },
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  async mounted() {
    this.ShowWallet()
    this.GetDataLotto()
    this.IntervalTime = setInterval(() => {
      // this.ShowWallet()
      this.CloseTimeShow = this.CalDiffTime()
      if (this.CloseTimeShow === '0 วัน 00 : 00 : 00') {
        this.$router.push({ name: 'tanghuay-round' })
      }
    }, 500)
  },
  methods: {
    toggleShowNumber() {
      this.isShowNumber = !this.isShowNumber
    },
  },
}
</script>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import "@core/scss/vue/pages/page-auth.scss";

.form-control {
  padding: 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0rem;
}

.style-1 {
  .otp-input-8-field input {
    border-radius: 5;
    border: 2px solid #c50000;

    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

.square-button {
  width: 100px !important;
  height: 100px !important;
}
</style>
